export default {
  components: {},
  computed: {},
  data() {
    return {};
  },
  mounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = 0;
    window.userHeader.titleText("寄存规则");
  },
  unmounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = "1.6rem";
  }
};